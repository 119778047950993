body {
 margin: 0;
 font-family: Inter, "Helvetica Neue", Helvetica;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
